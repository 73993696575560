import api from '@/libs/axios'
export default {
  async create(payload) {
    const response = await api.post('/earns/admin/configs', payload)
    return response.data.data.new_document || null
  },
  async update(payload) {
    let response = await api.put('/earns/admin/configs', payload)
    return response
  },
  async delete(payload) {
    const response = await api.delete('/earns/admin/configs', {
      data: payload,
    })
    return response
  },
  async getList(params) {
    let response = await api.get('/earns/admin/configs', {
      params,
    })
    return response
  },
  async get(params) {
    let response = await api.get('/earns/admin/configs/detail', {
      params,
    })
    return response.data.data.document || null
  },
  async getCategories(params = {}) {
    let response = await api.get('/earns/admin/configs/categories', {
      params,
    })
    return response.data.data.category_list || null
  },
}
