<template>
<div v-if="isDisplay" class="border-top border-bottom mb-1 py-1">

  <b-row>
    <b-col cols="3">
      <b-form-group label="Category" label-for="category">
        <b-form-input
          v-model="dataForm.category"
          id="category"
          type="text"
          placeholder="Category"
        />
      </b-form-group>
    </b-col>
    <b-col cols="3">
      <b-form-group label="Key" label-for="key">
        <b-form-input
          v-model="dataForm.key"
          id="key"
          type="text"
          placeholder="Key"
        />
      </b-form-group>
    </b-col>
    <b-col cols="2">
      <b-form-group label="Type" label-for="type">
        <b-form-select v-model="dataForm.type" :options="types" />
      </b-form-group>
    </b-col>
    <b-col cols="4">
      <b-form-group label="Value" label-for="value">
        <b-form-checkbox
          v-if="dataForm.type && dataForm.type == 'boolean'"
          v-model="dataForm.value"
          class="custom-control-primary"
          name="check-button"
          switch
        >
          <span class="switch-icon-left">
            <feather-icon icon="CheckIcon" />
          </span>
          <span class="switch-icon-right">
            <feather-icon icon="CheckIcon" />
          </span>
        </b-form-checkbox>
        <b-form-input
          v-else
          v-model="dataForm.value"
          id="value"
          :type="
            dataForm.type && dataForm.type != 'string'
              ? dataForm.type
              : 'text'
          "
          placeholder="Value"
        />
      </b-form-group>
    </b-col>
  </b-row>

  <div class="d-flex justify-content-between">
    <b-button @click="create" class="mt-0 mt-md-2 mr-1" variant="primary">
      Save
    </b-button>
    <b-button
      @click="showForm"
      class="mt-0 mt-md-2"
      variant="secondary"
    >
      Cancel
    </b-button>
  </div>

</div>
</template>

<script>
import { heightTransition } from "@core/mixins/ui/transition";
import service from "../../service";
export default {
  mixins: [heightTransition],
  props: {
    types: { type: Array, required: true },
  },
  data() {
    return {
      isDisplay: false,
      dataForm: {},
    };
  },
  watch: {
    "dataForm.type": function (newValue) {
      if (newValue == "boolean") {
        this.dataForm.value = false;
      }
    },
  },
  methods: {
    showForm() {
      this.isDisplay = !this.isDisplay;
    },
    async create() {
      if (this.dataForm.type == "number") {
        this.dataForm.value = parseInt(this.dataForm.value);
      }
      if (this.dataForm.type === 'object') {
        this.dataForm.value = JSON.parse(this.dataForm.value)
      }
      let res = await service.create(this.dataForm);
      if (res) {
        this.$store.dispatch("pushSuccessNotify", { text: "Successfully!" });
        this.showForm();
        this.$emit("getList");
        this.dataForm = {};
      }
    },
  },
};
</script>
