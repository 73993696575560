<template>
  <div class="page-content">
    <b-row>
      <b-col md="10">
        <page-breadcrumb title="Configs" class="mb-1" />
      </b-col>
    </b-row>
    <b-card>
      <fomula-config-table />
    </b-card>
  </div>
</template>
<script>
import FomulaConfigTable from "./_components/FomulaConfigTable.vue"
// import service from "../service"
export default {
  components:{
    FomulaConfigTable
  },
  data(){
    return{
    }
  },
  created(){
  },
  methods:{
  }
}
</script>
