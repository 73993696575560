<template>
  <div>
    <b-row>
      <b-col md="3">
        <b-form-group label="Category">
          <b-form-select v-model="filter.category" :options="categoryOptions" />
        </b-form-group>
      </b-col>
      <b-col md="4">
        <b-form-group label="Search by key">
          <b-input v-model="filter.key" />
        </b-form-group>
      </b-col>
      <b-col cols="2 ml-auto pt-2">
        <b-button @click="showForm" variant="primary">
          <feather-icon icon="PlusIcon" class="mr-50" />
          <span>Add Config</span>
        </b-button>
      </b-col>
    </b-row>
    <config-form-create
      @getList="getConfigs"
      ref="configFormCreate"
      :types="types"
    />
    <b-table-simple caption-top responsive bordered>
      <b-thead head-variant="light">
        <b-tr>
          <b-th class="text-center">STT</b-th>
          <b-th class="text-center">Key</b-th>
          <b-th class="text-center">Category</b-th>
          <b-th class="text-center">Type</b-th>
          <b-th class="text-center">Value</b-th>
          <b-th class="text-center">Actions</b-th>
        </b-tr>
      </b-thead>
      <b-tbody>
        <template v-for="(config, index) in configs">
          <b-tr :key="config.id || index">
            <b-th class="text-center">{{ index + 1 }}</b-th>
            <b-th class="text-center"> {{ config.config_key }} </b-th>
            <b-th class="text-center"> {{ config.category }}</b-th>
            <b-th class="text-center"> {{ config.type }}</b-th>

            <b-th class="text-center">
              <b-form-checkbox
                v-if="configId == config.id && type == 'boolean'"
                v-model="value"
                class="custom-control-primary"
                name="check-button"
                switch
              />
              <b-form-input
                v-else-if="configId == config.id && (type == 'number' || type == 'string')"
                v-model="value"
                class="custom-control-primary"
                name="check-button"
              />
              <span v-else>{{ reduceTextLength(config.data.value) }}</span>
            </b-th>
            <b-th class="text-center">
              <div class="d-flex justify-content-center">
                <b-button
                  v-if="config.type != 'object' && config.type != 'array'"
                  variant="gradient-warning"
                  style="margin-right: 5px"
                  class="btn-icon"
                  @click="editOrUpdateConfig(index)"
                >
                  {{ configId == config.id ? "Save" : "Edit" }}
                </b-button>
                <b-button
                  v-else
                  variant="gradient-warning"
                  style="margin-right: 5px"
                  class="btn-icon"
                  @click="editItem(config.config_key)"
                >
                <feather-icon v-if="config.type != 'object' && config.type != 'array'" icon="EditIcon" />
                <feather-icon v-else icon="EditIcon" />
              </b-button>
              <b-button
                @click="confirmDelete(config.id)"
                variant="gradient-danger"
                class="btn-icon"
              >
                <feather-icon icon="Trash2Icon" />
              </b-button>
              </div>
            </b-th>
          </b-tr>

        </template>
      </b-tbody>
    </b-table-simple>

  </div>
</template>
<script>
import service from "../../service";

import ConfigFormCreate from "./ConfigFormCreate.vue";

export default {
  components: {
    ConfigFormCreate,
  },
  data(){
    return{
      configs: [],
      configId: null,
      value: null,
      type: null,
      filter:{
        category: null
      },
      categoryOptions:[
        {text: "All", value: null},
      ],
      types: [
        {
          text: "String",
          value: "string",
        },
        {
          text: "Boolean",
          value: "boolean",
        },
        {
          text: "Number",
          value: "number",
        },
        {
          text: "Object",
          value: "object",
        }
      ],
      config_key: null
    }
  },
  watch: {
    filter:{
      deep: true,
      handler: function(newVal){
        if(newVal){
          this.getConfigs();
        }
      }
    },
  },
  async created() {
    this.getConfigs();
    this.getCategories();
  },
  methods: {
    async getConfigs(){
      let response = await service.getList({
        query: JSON.stringify(this.filter)
      });
      if (response){
        this.configs = response.data.data.list;
      }
    },
    async getCategories() {
      let categories = await service.getCategories();
      let options = categories.map(item => {
        return { text: item.toUpperCase(), value: item };
      });
      this.categoryOptions = [{text: "All", value: null}, ...options]
    },
    showForm() {
      this.$refs.configFormCreate.showForm();
    },
    confirmDelete(id) {
      this.$bvModal
        .msgBoxConfirm("Are you sure ?", {
          title: "Please Confirm",
          size: "md",
          okVariant: "danger",
          okTitle: "Yes",
          cancelTitle: "No",
          cancelVariant: "outline-secondary",
          hideHeaderClose: true,
          centered: true,
        })
        .then((value) => {
          if (value === true) {
            this.deleteItem(id);
          }
        });
    },
    async deleteItem(id) {
      await service.delete({ id: id });
      this.getConfigs();
    },
    editItem(key) {
      this.$router.push({
        name: "earn-fomula-config-detail",
        params: { config_key: key },
      });
    },
    reduceTextLength(text){
      if (JSON.stringify(text).length > 50){
        text = JSON.stringify(text);
        return text.slice(0, 40)+"..."
      }
      return text
    },
    async editOrUpdateConfig(index) {
      if (
        !this.configId ||
        (this.configId && this.configId != this.configs[index].id)
      ) {
        this.configId = this.configs[index].id;
        this.value = this.configs[index].data.value;
        this.type = this.configs[index].type;
      } else {
        this.configs[index].data.value = (this.type == "number") ? parseFloat(this.value) : this.value;
        let dataUpdate = {
          ...this.configs[index],
        };
        let response = await service.update(dataUpdate);
        if (response.data.type == "SUCCESS") {
          this.resetData();
          this.$emit("getList");
        }
      }
    },
    resetData() {
      this.configId = null;
      this.type = null;
      this.value = null;
    },
  },
};
</script>
<style scoped>
  .item-thumbnail{
    max-width: 50px;
  }
  .table th{
    padding: 0.72rem 1rem;
  }
</style>
